import { httpApi } from './http.api';

export interface Policy {
  id?: string;
  content: string;
  createdAt?: string;
}

export const getPolicy = (): Promise<Policy> => {
  return httpApi.get<Policy>('policy').then(({ data }) => data);
};

export const updatePolicy = (policy: Policy): Promise<Policy> => {
  return httpApi.patch<Policy>(`policy`, JSON.parse(JSON.stringify(policy))).then(({ data }) => data);
};
