import { httpApi } from './http.api';

export interface Statistic {
  id: number;
  value: number;
  name: string;
}

export const getStatistics = async (): Promise<Statistic[]> => {
  const response = await httpApi.get<Statistic[]>('/statistics');
  return response.data;
};
