import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { CreateProductForm } from '@app/components/forms/CreateProductForm/CreateProductForm';
import { useLocation } from 'react-router-dom';
import { EditTypeProductForm } from '@app/components/forms/EditTypeProductForm/EditTypeProductForm';

const TypeProductEditPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageTitle>{t('typeProduct.edit')}</PageTitle>
      <BaseRow gutter={[30, 30]}>
        <BaseCol xs={24} sm={24} xl={24}>
          <BaseCard id="validation form" title={t('forms.editTypeProduct.name')} padding="1.25rem">
            <EditTypeProductForm />
          </BaseCard>
        </BaseCol>
      </BaseRow>
    </>
  );
};

export default TypeProductEditPage;
