import { httpApi } from './http.api';

export interface TypeProduct {
  id: string;
  name: string;
  numberOfProduct: number;
}

export interface TypeProductListResponse {
  data: TypeProduct[];
}

export interface CreateTypeProductRequest {
  name: string;
}

export const getTypeProductList = (): Promise<TypeProductListResponse> =>
  httpApi.get<TypeProductListResponse>('admin/type-product').then(({ data }) => data);

export const createTypeProduct = (data: CreateTypeProductRequest) => httpApi.post('admin/type-product', data);

export const deleteTypeProduct = (id: string) => httpApi.delete(`admin/type-product/${id}`);

export const updateTypeProduct = (id: string, data: any) =>
  httpApi.patch(`admin/type-product/${id}`, data).then(({ data }) => data);
