import React from 'react';
import { TableProduct } from '../../../components/tables/tableProduct/tableProduct';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';

const ProductListPage: React.FC = () => {
  const desktopLayout = <TableProduct />;
  return (
    <>
      <PageTitle>Danh sách sản phẩm</PageTitle>
      {desktopLayout}
    </>
  );
};

export default ProductListPage;
