import { Priority } from '../constants/enums/priorities';
import { httpApi } from './http.api';
import { TypeProduct } from './type-product.api';

export interface Tag {
  value: string;
  priority: Priority;
}

export interface Product {
  id?: string;
  name: string;
  description?: string;
  originPrice: number;
  salePrice: number;
  info?: string;
  guide?: string;
  effect?: string;
  typeProduct: TypeProduct;
  remain: number;
  deleteAt?: Date;
  objectOfUse?: string;
  images?: string[];
}

export interface Pagination {
  page?: number;
  limit?: number;
  totalDocs?: number;
}

export interface ProductListResponse {
  docs: Product[];
  totalDocs: number;
  limit: number;
  page: number;
  totalPages: number;
}

export const createProduct = (product: Product): Promise<Product> => {
  return httpApi.post<Product>('admin/product', JSON.parse(JSON.stringify(product))).then(({ data }) => data);
};

export const updateProduct = (product: Product): Promise<Product> => {
  return httpApi
    .patch<Product>(`admin/product/${product.id}`, JSON.parse(JSON.stringify(product)))
    .then(({ data }) => data);
};

export const getProductList = async (pagination: Pagination, search?: string): Promise<ProductListResponse> => {
  try {
    const response = await httpApi.get<ProductListResponse>('admin/product', { params: { ...pagination, search } });
    return response.data;
  } catch (e: any) {
    throw new Error(e);
  }
};

export const getProduct = (id: string): Promise<Product> => {
  return httpApi.get<Product>(`admin/product/${id}`).then(({ data }) => data);
};

export const deleteProduct = (id: string) => {
  return httpApi.delete<void>(`admin/product/${id}`).then(({ data }) => data);
};
