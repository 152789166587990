import styled from 'styled-components';
import { BORDER_RADIUS, FONT_SIZE, FONT_WEIGHT, media } from '@app/styles/themes/constants';
import { BaseTypography } from '@app/components/common/BaseTypography/BaseTypography';
import { Button } from 'antd';

export const Header = styled.div`
  height: 5.5rem;
  margin-left: 1.5625rem;
  display: flex;
  align-items: center;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 5.25rem;
  position: relative;
  box-shadow: var(--box-shadow);
  border-radius: ${BORDER_RADIUS};
  transition: 0.3s;

  [data-theme='dark'] & {
    background: var(--secondary-background-color);
  }

  &:hover {
    box-shadow: var(--box-shadow-hover);
  }
`;

export const CustomButton = styled(Button)`
  border: 0;
  color: var(--text-main-color);
  font-weight: ${FONT_WEIGHT.semibold};
  border-radius: 0.625rem;
  padding: 0.5rem 1rem;
  transition: 0.3s;

  &:hover {
    background: var(--primary-color);
    color: white;
  }
`;

export const InfoWrapper = styled.div`
  padding: 0.5rem;

  @media only screen and ${media.xl} {
    padding: 0.5rem;
  }

  @media only screen and ${media.xxl} {
    padding: 0.5rem;
  }
`;

export const InfoHeader = styled.div`
  display: flex;
  margin-bottom: 1rem;
  max-width: 100%;
  flex-direction: column;

  @media only screen and ${media.md} {
    margin-bottom: 0.625rem;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  @media only screen and ${media.xxl} {
    margin-bottom: 1.25rem;
  }
`;

export const Title = styled.div`
  font-size: ${FONT_SIZE.lg};
  font-weight: ${FONT_WEIGHT.semibold};
  width: 100%;

  color: var(--text-main-color);

  @media only screen and ${media.md} {
    font-size: ${FONT_SIZE.xxxxl};
  }
`;

export const DateTime = styled(BaseTypography.Text)`
  font-size: ${FONT_SIZE.md};
  color: var(--text-main-color);
  padding-left: 3.7rem;
`;

export const Description = styled.div`
  margin-top: 2rem;
  padding: 1rem;

  @media only screen and ${media.md} {
    padding: 2rem;
  }

  @media only screen and ${media.lg} {
    padding: 3rem;
  }

  img {
    max-width: 100%;
  }
`;
