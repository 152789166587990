import { httpApi } from './http.api';

export interface News {
  id?: string;
  title: string;
  subTitle: string;
  content: string;
  thumbnailImage: string;
  createdAt?: string;
}

export interface Pagination {
  page?: number;
  limit?: number;
  totalDocs?: number;
}

export interface NewsListResponse {
  docs: News[];
  totalDocs: number;
  limit: number;
  page: number;
  totalPages: number;
}

export const createNews = (news: News): Promise<News> => {
  return httpApi.post<News>('admin/news', JSON.parse(JSON.stringify(news))).then(({ data }) => data);
};

export const getNews = (
  pagination: Pagination,
  search?: string,
  startDate?: Date,
  endDate?: Date,
): Promise<NewsListResponse> => {
  return httpApi
    .get<NewsListResponse>('admin/news', { params: { ...pagination, search, startDate, endDate } })
    .then(({ data }) => data);
};

export const updateNews = (news: News): Promise<News> => {
  return httpApi.patch<News>(`admin/news/${news.id}`, JSON.parse(JSON.stringify(news))).then(({ data }) => data);
};

export const deleteNews = (id: string): Promise<void> => {
  return httpApi.delete(`admin/news/${id}`).then(({ data }) => data);
};
