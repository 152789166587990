import React, { useCallback, useEffect, useState } from 'react';
import { BaseArticle } from '@app/components/common/BaseArticle/BaseArticle';
import { BaseFeed } from '@app/components/common/BaseFeed/BaseFeed';
import { NewsFilter } from './NewsFilter/NewsFilter';
import { getNews, News, Pagination } from '@app/api/news.api';
import { BaseEmpty } from '@app/components/common/BaseEmpty/BaseEmpty';
import { useMounted } from '@app/hooks/useMounted';
import { Pagination as Pagi, PaginationProps } from 'antd';

const initialPagination: Pagination = {
  page: 1,
  limit: 10,
};

export const NewsFeed: React.FC = () => {
  const itemRender: PaginationProps['itemRender'] = (_, type, originalElement) => {
    if (type === 'prev') {
      return <a>Previous</a>;
    }
    if (type === 'next') {
      return <a>Next</a>;
    }
    return originalElement;
  };

  const [search, setSearch] = useState<string | undefined>(undefined);
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);

  const [totalDocs, setTotalDocs] = useState<number>(0);
  const [data, setData] = useState<{ data: News[]; pagination: Pagination }>({
    data: [],
    pagination: initialPagination,
  });
  const [loaded, setLoaded] = useState<boolean>(false);
  const { isMounted } = useMounted();

  const fetch = useCallback(
    async (pagination: Pagination, search?: string, startDate?: Date, endDate?: Date) => {
      setSearch(search);
      setStartDate(startDate);
      setEndDate(endDate);
      await getNews(pagination, search, startDate, endDate)
        .then((res) => {
          if (isMounted.current) {
            setData({
              data: res.docs,
              pagination: {
                page: res.page,
                limit: res.limit,
                totalDocs: res.totalDocs,
              },
            });
            setTotalDocs(res.totalDocs);
          }
        })
        .finally(() => setLoaded(true));
    },
    [isMounted],
  );

  useEffect(() => {
    fetch(initialPagination);
  }, [fetch]);

  const handlePageChange = (page: number, pageSize: number) => {
    fetch({ page, limit: pageSize }, search, startDate, endDate);
  };

  return (
    <NewsFilter news={data.data} fetchNews={fetch}>
      {({ filteredNews }) =>
        filteredNews?.length || !loaded ? (
          <BaseFeed>
            {filteredNews.map((news, index) => (
              <BaseArticle key={index} news={news} />
            ))}
            {filteredNews?.length > 0 ? (
              <Pagi total={totalDocs} itemRender={itemRender} onChange={handlePageChange} />
            ) : null}
          </BaseFeed>
        ) : (
          <BaseEmpty />
        )
      }
    </NewsFilter>
  );
};
