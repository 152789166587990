import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { InputNumber } from '@app/components/common/inputs/InputNumber/InputNumber';
import { BaseSelect, Option } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { notificationController } from '@app/controllers/notificationController';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import * as S from './EditProductForm.styles';
import { PlusOutlined } from '@ant-design/icons';
import { Modal, Upload } from 'antd';
import type { RcFile, UploadProps } from 'antd/es/upload';
import type { UploadFile } from 'antd/es/upload/interface';
import { use } from 'echarts';
import { getTypeProductList } from '@app/api/type-product.api';
import { useMounted } from '@app/hooks/useMounted';
import { getSignUrl } from '@app/api/media.api';
import axios from 'axios';
import { Product, createProduct, updateProduct } from '@app/api/product.api';
import { useLocation, useNavigate } from 'react-router-dom';
import { set } from 'lodash';

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const normFile = (e = { fileList: [] }) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

export const EditProductForm: React.FC = () => {
  const [isFieldsChanged, setFieldsChanged] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [typeProductList, setTypeProductList] = useState([]);
  const { isMounted } = useMounted();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { product } = state;

  const fetch = useCallback(() => {
    getTypeProductList().then((res: any) => {
      if (isMounted.current) {
        setTypeProductList(res.data);
      }
    });
  }, [isMounted]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const uploadFile = async () => {
    const fileURLList: Array<string> = [];
    for (const fileObject of fileList) {
      if (fileObject.status === 'done') {
        if (fileObject.url) fileURLList.push(fileObject.url);
        continue;
      }
      const file = fileObject.originFileObj;
      if (file instanceof File) {
        await getSignUrl({ fileName: file.name, contentType: file.type, isPublic: true, folder: 'product' }).then(
          async (data) => {
            const urlObj = new URL(data.url);
            const url = `${urlObj.origin}${urlObj.pathname}`;
            await axios
              .put(data.url, file, {
                headers: {
                  'Content-Type': file.type,
                },
              })
              .then(() => {
                fileURLList.push(url);
              });
          },
        );
      }
    }
    return fileURLList;
  };

  const onFinish = async (values: any = {}) => {
    setLoading(true);
    const fileURLList = await uploadFile();

    const data: Product = {
      id: product.id,
      name: values.name,
      typeProduct: values.typeProduct,
      remain: values.remain,
      salePrice: values.salePrice,
      originPrice: values.originPrice,
      description: values.description,
      guide: values.guide,
      effect: values.effect,
      objectOfUse: values.objectOfUse,
      images: fileURLList,
    };

    await updateProduct(data)
      .then((response: any) => {
        notificationController.success({ message: response.message });
        navigate('/product/list');
      })
      .catch((error) => {
        notificationController.error(error.message);
      });
    setLoading(false);
  };
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState<UploadFile[]>(
    product.images.map((image: string, index: number) => ({
      uid: index.toString(),
      name: image,
      status: 'done',
      url: image,
    })),
  );

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
  };

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    setFieldsChanged(true);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  return (
    <BaseButtonsForm
      {...formItemLayout}
      isFieldsChanged={isFieldsChanged}
      onFieldsChange={() => setFieldsChanged(true)}
      name="validateForm"
      footer={
        <BaseButtonsForm.Item>
          <BaseButton type="primary" htmlType="submit" loading={isLoading}>
            {t('common.save')}
          </BaseButton>
        </BaseButtonsForm.Item>
      }
      onFinish={onFinish}
    >
      {/* Tên sản phẩm */}
      <BaseButtonsForm.Item
        name="name"
        label={t('forms.addProduct.name_product')}
        hasFeedback
        initialValue={product.name}
        rules={[{ required: true, message: t('forms.addProduct.nameProductError') }]}
      >
        <S.InputsWrapper>
          <BaseInput placeholder={t('forms.addProduct.name_product')} defaultValue={product.name} />
        </S.InputsWrapper>
      </BaseButtonsForm.Item>
      {/* Loại sản phẩm */}
      <BaseButtonsForm.Item
        name="typeProduct"
        label={t('forms.addProduct.type_product')}
        hasFeedback
        initialValue={product.typeProduct.id}
        rules={[{ required: true, message: t('forms.addProduct.typeProductError') }]}
      >
        <BaseSelect
          placeholder={t('forms.addProduct.type_product')}
          options={typeProductList.map((value: any) => {
            return {
              label: value.name,
              value: value.id,
            };
          })}
          defaultValue={product.typeProduct.id}
        ></BaseSelect>
      </BaseButtonsForm.Item>
      {/* Số lượng sản phẩm */}
      <BaseButtonsForm.Item label={t('forms.addProduct.number')}>
        <label>
          <BaseButtonsForm.Item
            name="remain"
            noStyle
            hasFeedback
            rules={[{ required: true, message: 'Số lượng sản phẩm là bắt buộc' }]}
            initialValue={product.remain}
          >
            <InputNumber min={1} max={200} defaultValue={product.remain} required={true} />
          </BaseButtonsForm.Item>
        </label>
        <span> {t('forms.addProduct.product')}</span>
      </BaseButtonsForm.Item>
      {/* Giá bán */}
      <BaseButtonsForm.Item
        name="salePrice"
        label={t('forms.addProduct.priceSale')}
        hasFeedback
        rules={[{ required: true, message: t('forms.addProduct.salePriceError') }]}
        initialValue={product.salePrice}
      >
        <S.InputsWrapper>
          <BaseInput placeholder={t('forms.addProduct.priceSale')} defaultValue={product.salePrice} />
        </S.InputsWrapper>
      </BaseButtonsForm.Item>
      {/* Giá gốc */}
      <BaseButtonsForm.Item
        name="originPrice"
        label={t('forms.addProduct.priceOrigin')}
        hasFeedback
        rules={[{ required: true, message: t('forms.addProduct.originPriceError') }]}
        initialValue={product.originPrice}
      >
        <S.InputsWrapper>
          <BaseInput placeholder={t('forms.addProduct.priceOrigin')} defaultValue={product.originPrice} />
        </S.InputsWrapper>
      </BaseButtonsForm.Item>
      {/* Mô tả */}
      <BaseButtonsForm.Item name="description" label={t('forms.addProduct.description')}>
        <S.InputsWrapper>
          <BaseInput.TextArea rows={4} defaultValue={product.description} />
        </S.InputsWrapper>
      </BaseButtonsForm.Item>
      {/* Tác dụng */}
      <BaseButtonsForm.Item name="effect" label={t('forms.addProduct.effect')}>
        <S.InputsWrapper>
          <BaseInput.TextArea rows={4} defaultValue={product.effect} />
        </S.InputsWrapper>
      </BaseButtonsForm.Item>
      {/* Hướng dẫn sử dụng */}
      <BaseButtonsForm.Item name="guide" label={t('forms.addProduct.guide')}>
        <S.InputsWrapper>
          <BaseInput.TextArea rows={4} defaultValue={product.guide} />
        </S.InputsWrapper>
      </BaseButtonsForm.Item>
      {/* Đối tượng sử dụng */}
      <BaseButtonsForm.Item name="objectOfUse" label={t('forms.addProduct.objectOfUse')}>
        <S.InputsWrapper>
          <BaseInput.TextArea rows={4} defaultValue={product.objectOfUse} />
        </S.InputsWrapper>
      </BaseButtonsForm.Item>
      {/* Up ảnh */}
      <BaseButtonsForm.Item name="upload" label={t('forms.addProduct.upload')}>
        <Upload listType="picture-card" fileList={fileList} onPreview={handlePreview} onChange={handleChange}>
          {fileList.length >= 8 ? null : uploadButton}
        </Upload>
        <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </BaseButtonsForm.Item>
    </BaseButtonsForm>
  );
};
