import React, { useState } from 'react';
import { Button, Modal, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { CheckBox } from '@app/components/header/components/searchDropdown/searchOverlay/SearchFilter/SearchFilter.styles';
import type { CheckboxProps } from 'antd';
import { update } from 'lodash';
import { updateOrder } from '@app/api/order.api';

interface SubmitShippedProps {
  id: string;
  shipped: boolean;
}

const SubmitShipped: React.FC<SubmitShippedProps> = ({ id, shipped }) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(shipped);
  const { t } = useTranslation();
  const showModal = () => {
    setOpen(true);
  };
  const onChange: CheckboxProps['onChange'] = (e) => showModal();
  const handleOk = async () => {
    setLoading(true);
    await updateOrder(id, shipped ? false : true)
      .then(() => {
        setLoading(false);
        setOpen(false);
        notification.success({
          message: 'Thay đổi trạng thái giao hàng thành công',
        });
        setChecked(!checked);
      })
      .catch(() => {
        setLoading(false);
        notification.error({
          message: 'Thay đổi trạng thái giao hàng thất bại',
        });
      });
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <CheckBox onChange={onChange} checked={checked}></CheckBox>
      <Modal
        open={open}
        title="Thay đổi trạng thái giao hàng"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Hủy
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
            Thay đổi
          </Button>,
        ]}
      >
        Thay đổi trạng thái giao hàng
      </Modal>
    </>
  );
};

export default SubmitShipped;
