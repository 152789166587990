import { httpApi } from './http.api';

export interface Introduction {
  id?: string;
  content: string;
  createdAt?: string;
}

export const getIntroduction = (): Promise<Introduction> => {
  return httpApi.get<Introduction>('introduction').then(({ data }) => data);
};

export const updateIntroduction = (introduction: Introduction): Promise<Introduction> => {
  return httpApi.patch<Introduction>(`introduction`, JSON.parse(JSON.stringify(introduction))).then(({ data }) => data);
};
