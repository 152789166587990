import React, { useEffect, useState, useCallback } from 'react';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { ColumnsType } from 'antd/es/table';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useTranslation } from 'react-i18next';
import { useMounted } from '@app/hooks/useMounted';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { useNavigate } from 'react-router-dom';
import OrderDetailModal from '@app/components/modals/OrderModals/orderDetailModal';

import SubmitShipped from '@app/components/modals/OrderModals/submitShippedModal';
import { Order, OrderList, Pagination, deleteOrder, getOrderList } from '@app/api/order.api';
import { Dates } from '@app/constants/Dates';
import { Modal, notification } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
const { confirm } = Modal;

const initialPagination: Pagination = {
  page: 1,
  limit: 10,
};

export const TableOrder: React.FC = () => {
  const [tableData, setTableData] = useState<{ data: OrderList[]; pagination: Pagination; loading: boolean }>({
    data: [],
    pagination: initialPagination,
    loading: false,
  });
  const { t } = useTranslation();
  const { isMounted } = useMounted();
  const navigate = useNavigate();

  const fetch = useCallback(
    async (pagination: Pagination) => {
      setTableData((tableData) => ({ ...tableData, loading: true }));
      await getOrderList(pagination)
        .then((response) => {
          if (isMounted.current) {
            setTableData({
              data: response.docs,
              pagination: {
                page: response.page,
                limit: response.limit,
                totalDocs: response.totalDocs,
              },
              loading: false,
            });
          }
        })
        .catch(() => {
          if (isMounted.current) {
            setTableData((tableData) => ({ ...tableData, loading: false }));
          }
        });
    },
    [isMounted],
  );

  useEffect(() => {
    fetch(initialPagination);
  }, [fetch]);

  const handleTableChange = (pagination: any) => {
    const newPagination: Pagination = {
      page: pagination.current,
      limit: pagination.pageSize,
    };
    fetch(newPagination);
  };

  const warning = async (orderId: string) => {
    confirm({
      title: 'Xác nhận xóa sản phẩm này',
      icon: <ExclamationCircleFilled />,
      content: 'Bạn có chắc chắn muốn xóa sản phẩm này không?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      async onOk() {
        await handleDeleteRow(orderId);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const handleDeleteRow = async (orderId: string) => {
    await deleteOrder(orderId)
      .then((data: any) => {
        notification.success({
          message: 'Xóa thành công',
        });
        fetch(tableData.pagination);
      })
      .catch((error: any) => {
        notification.error({
          message: error.message,
        });
      });
    fetch(tableData.pagination);
  };

  const columns: ColumnsType<Order> = [
    {
      title: t('tableOrder.customerName'),
      dataIndex: 'customerName',
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: 'Email',
      dataIndex: 'customerEmail',
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'customerPhone',
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: 'Tổng tiền',
      dataIndex: 'total',
      sorter: (a, b) => a.total - b.total,
      render: (text: number) => <span>{text}</span>,
    },
    {
      title: t('tableOrder.date'),
      dataIndex: 'createdAt',
      sorter: (a, b) => (Date.parse(a.createdAt) < Date.parse(b.createdAt) ? -1 : 1),
      render: (text: string) => {
        return <span>{Dates.format(text || '', 'L')}</span>;
      },
    },
    {
      title: t('tableOrder.shipped'),
      dataIndex: 'shipped',
      render: (text: string, record: { id: string; shipped: boolean }) => {
        return <SubmitShipped shipped={record.shipped} id={record.id} />;
      },
    },
    {
      title: t('tableOrder.actions'),
      dataIndex: 'actions',
      width: '15%',
      render: (text: string, record: { id: string }) => {
        return (
          <BaseSpace>
            <OrderDetailModal id={record.id} />
            <BaseButton type="default" danger onClick={() => warning(record.id)}>
              {t('tableOrder.delete')}
            </BaseButton>
          </BaseSpace>
        );
      },
    },
  ];

  return (
    <BaseTable
      columns={columns}
      dataSource={tableData.data}
      pagination={{
        current: tableData.pagination.page,
        pageSize: tableData.pagination.limit,
        total: tableData.pagination.totalDocs,
      }}
      loading={tableData.loading}
      onChange={handleTableChange}
      scroll={{ x: 800 }}
      bordered
    />
  );
};
