import React from 'react';
import { Navigate } from 'react-router-dom';
import { WithChildrenProps } from '@app/types/generalTypes';
import { readAccessToken } from '@app/services/cookie.service.';
import { readToken } from '@app/services/localStorage.service';

const parseJwt = (token: string | null) => {
  if (!token) return false;
  const decode = JSON.parse(atob(token.split('.')[1]));
  if (decode.exp * 1000 < new Date().getTime()) {
    console.log('Token expired');
    return false;
  }
  return true;
};

const RequireAuth: React.FC<WithChildrenProps> = ({ children }) => {
  const token = readToken();
  const check = parseJwt(token);

  return check ? <>{children}</> : <Navigate to="/auth/login" replace />;
};

export default RequireAuth;
