// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// @ts-nocheck
import { Component, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import BlotFormatter from 'quill-blot-formatter';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { notificationController } from '@app/controllers/notificationController';
import './styles.css';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import * as S from './EditIntroductionEditor.styles';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { useTranslation } from 'react-i18next';
import type { RcFile, UploadProps } from 'antd/es/upload';
import type { UploadFile } from 'antd/es/upload/interface';
import { Modal, Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import axios from 'axios';
import { getSignUrl } from '@app/api/media.api';
import dynamic from 'next/dynamic';
import { Introduction, updateIntroduction } from '@app/api/introduction.api';
import { useLocation, useNavigate } from 'react-router-dom';

Quill.register('modules/blotFormatter', BlotFormatter);
const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const ReactQuill = dynamic(
  async () => {
    const { default: RQ } = await import('react-quill');

    return ({ forwardedRef, ...props }) => <RQ ref={forwardedRef} {...props} />;
  },
  {
    ssr: false,
  },
);

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const EditIntroductionEditor: React.FC = () => {
  const { state } = useLocation();
  const { introduction } = state;
  const [editorHtml, setEditorHtml] = useState(introduction.content || '');
  const [isLoading, setLoading] = useState(false);
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const quillRef = useRef();
  const { t } = useTranslation();

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState<UploadFile[]>([
    {
      uid: '1',
      name: 'thumbnail.png',
      status: 'done',
      url: introduction.thumbnailImage || '',
    },
  ]);

  const navigate = useNavigate();

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
  };

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFieldsChanged(true);
    setFileList(newFileList);
  };

  const uploadFile = async (file: RcFile | File) => {
    let res = '';
    await getSignUrl({ fileName: file.name, contentType: file.type, isPublic: true, folder: 'introduction' })
      .then(async (data) => {
        const urlObj = new URL(data.url);
        const url = `${urlObj.origin}${urlObj.pathname}`;
        await axios
          .put(data.url, file, {
            headers: {
              'Content-Type': file.type,
            },
          })
          .then(() => {
            res = url.toString();
          });
      })
      .catch((error) => {
        notificationController.error(error);
      });
    return res;
  };

  const imageHandler = useCallback(() => {
    const input = document.createElement('input');

    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      const formData = new FormData();

      formData.append('image', file);

      // Save current cursor state
      const range = quillRef.current.editor.getSelection(true);

      // Insert temporary loading placeholder image
      quillRef.current.editor.insertEmbed(
        range.index,
        'image',
        `${window.location.origin}/images/loaders/placeholder.gif`,
      );

      // Move cursor to right side of image (easier to continue typing)
      quillRef.current.editor.setSelection(range.index + 1);

      const imageURL = await uploadFile(file);

      // Remove placeholder image
      quillRef.current.editor.deleteText(range.index, 1);

      // Insert uploaded image
      // this.quill.insertEmbed(range.index, 'image', res.body.image);
      quillRef.current.editor.insertEmbed(range.index, 'image', imageURL);
    };
  }, []);

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [] }, { font: [] }],
          [{ size: [] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
          ['link', 'image', 'video'],
          ['clean'],
          [{ align: [] }],
          [{ color: [] }, { background: [] }],
        ],
        handlers: {
          image: imageHandler,
        },
      },
      blotFormatter: {},
    }),
    [],
  );

  const handleOnChange = (html: string) => {
    setFieldsChanged(true);
    setEditorHtml(html);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  useEffect(() => {
    const init = (quill) => undefined;
    const check = () => {
      if (quillRef.current) {
        init(quillRef.current);
        const delta = quillRef.current.getEditor().clipboard.convert(editorHtml);
        quillRef.current.getEditor().setContents(delta, 'silent');
        return;
      }
      setTimeout(check, 200);
    };
    check();
  }, [quillRef]);

  const onFinish = async (values: any = {}) => {
    setLoading(true);

    let fileURL = '';
    if (fileList[0].originFileObj) {
      fileURL = await uploadFile(fileList[0].originFileObj);
    } else {
      fileURL = fileList[0].url || '';
    }

    const data: Introduction = {
      id: introduction.id,
      content: editorHtml,
    };

    await updateIntroduction(data)
      .then((response: any) => {
        notificationController.success({ message: response.message });
        navigate('/introduction/');
      })
      .catch((error) => {
        notificationController.error(error.message);
      });
    setLoading(false);
  };

  return (
    <BaseButtonsForm
      {...formItemLayout}
      isFieldsChanged={isFieldsChanged}
      onFieldsChange={() => setFieldsChanged(true)}
      name="validateForm"
      footer={
        <BaseButtonsForm.Item>
          <BaseButton type="primary" htmlType="submit" loading={isLoading}>
            {t('common.save')}
          </BaseButton>
        </BaseButtonsForm.Item>
      }
      onFinish={onFinish}
    >
      <BaseButtonsForm.Item name="content" label="Nội dung chính sách" hasFeedback>
        <ReactQuill
          forwardedRef={quillRef}
          value={editorHtml}
          modules={modules}
          formats={formats}
          // bounds={'#root'}
          onChange={handleOnChange}
        />
      </BaseButtonsForm.Item>
    </BaseButtonsForm>
  );
};

const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
  'align',
  'color',
];

export default EditIntroductionEditor;
