import React from 'react';
import {
  FormOutlined,
  HomeOutlined,
  LayoutOutlined,
  LineChartOutlined,
  TableOutlined,
  UserOutlined,
  BlockOutlined,
  ShoppingCartOutlined,
  ShoppingOutlined,
  AuditOutlined,
  ContainerOutlined,
} from '@ant-design/icons';
import { ReactComponent as NftIcon } from '@app/assets/icons/nft-icon.svg';

export interface SidebarNavigationItem {
  title: string;
  key: string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
}

export const sidebarNavigation: SidebarNavigationItem[] = [
  {
    title: 'common.home',
    key: 'home',
    // TODO use path variable
    url: '/',
    icon: <HomeOutlined />,
  },
  {
    title: 'product.name',
    key: 'product',
    icon: <FormOutlined />,
    children: [
      {
        title: 'product.list',
        key: 'product.list',
        url: '/product/list',
      },
      {
        title: 'product.add',
        key: 'product.add',
        url: '/product/add',
      },
    ],
  },
  {
    title: 'typeProduct.name',
    key: 'typeProduct',
    icon: <FormOutlined />,
    children: [
      {
        title: 'typeProduct.list',
        key: 'typeProduct.list',
        url: '/typeProduct/list',
      },
      {
        title: 'typeProduct.add',
        key: 'typeProduct.add',
        url: '/typeProduct/add',
      },
    ],
  },
  {
    title: 'order.name',
    key: 'order',
    icon: <ShoppingCartOutlined />,
    url: '/order/list',
  },
  {
    title: 'news.title',
    key: 'news',
    icon: <FormOutlined />,
    children: [
      {
        title: 'news.list',
        key: 'news.list',
        url: '/news/list',
      },
      {
        title: 'news.add',
        key: 'news.add',
        url: '/news/add',
      },
    ],
  },
  {
    title: 'Chính sách',
    key: 'policy',
    icon: <AuditOutlined />,
    url: '/policy/',
  },
  {
    title: 'Giới thiệu',
    key: 'introduction',
    icon: <ContainerOutlined />,
    url: '/introduction/',
  },
];
