import CreateNewsEditor from '@app/components/news/CreateNewsEditor/CreateNewsEditor';
import NewsDetail from '@app/components/news/newsFeed/NewsDetail/NewsDetail';
import React from 'react';

const NewsDetailPage: React.FC = () => {
  const desktopLayout = <NewsDetail />;
  return <>{desktopLayout}</>;
};

export default NewsDetailPage;
