import React, { useCallback, useEffect, useState } from 'react';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { ColumnsType } from 'antd/lib/table';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useMounted } from '@app/hooks/useMounted';
import { useTranslation } from 'react-i18next';
import { TypeProduct, getTypeProductList } from '@app/api/type-product.api';
import { useNavigate } from 'react-router-dom';

export const TypeProductListPage: React.FC = () => {
  const [tableData, setTableData] = useState<{ data: TypeProduct[]; loading: boolean }>({
    data: [],
    loading: false,
  });
  const { t } = useTranslation();
  const { isMounted } = useMounted();
  const navigate = useNavigate();

  const fetch = useCallback(() => {
    setTableData((tableData) => ({ ...tableData, loading: true }));
    getTypeProductList().then((res) => {
      if (isMounted.current) {
        setTableData({ data: res.data, loading: false });
      }
    });
  }, [isMounted]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const handleTableChange = () => {
    fetch();
  };

  const handleDeleteRow = (typeProductId: string) => undefined;

  const columns: ColumnsType<TypeProduct> = [
    {
      title: 'ID',
      dataIndex: 'id',
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: t('typeProductList.name'),
      dataIndex: 'name',
      render: (text: string) => <span>{text}</span>,
      filterMode: 'tree',
      filterSearch: true,
    },
    {
      title: t('typeProductList.numberOfProduct'),
      dataIndex: 'numberOfProduct',
    },
    {
      title: t('typeProductList.actions'),
      dataIndex: 'actions',
      width: '15%',
      render: (text: string, record: { name: string; id: string }) => {
        return (
          <BaseSpace>
            <BaseButton
              type="ghost"
              onClick={() => {
                navigate(`/typeProduct/edit/${encodeURIComponent(record.id)}`, {
                  state: {
                    id: record.id,
                    name: record.name,
                  },
                });
              }}
            >
              {t('typeProductList.edit')}
            </BaseButton>
            <BaseButton type="default" danger onClick={() => handleDeleteRow(record.id)}>
              {t('typeProductList.delete')}
            </BaseButton>
          </BaseSpace>
        );
      },
    },
  ];

  return (
    <BaseTable
      columns={columns}
      dataSource={tableData.data}
      loading={tableData.loading}
      onChange={handleTableChange}
      scroll={{ x: 800 }}
      bordered
    />
  );
};

export default TypeProductListPage;
