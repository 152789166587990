import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { notificationController } from '@app/controllers/notificationController';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import * as S from './EditTypeProductForm.styles';
import { updateTypeProduct } from '@app/api/type-product.api';
import { useLocation, useNavigate } from 'react-router-dom';

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

export const EditTypeProductForm: React.FC = () => {
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { id, name } = state;

  const onFinish = async (values: any) => {
    if (values.name == name) {
      return;
    }
    setLoading(true);
    await updateTypeProduct(id, values)
      .then((data: any) => {
        setLoading(false);
        setFieldsChanged(false);
        notificationController.success({ message: data.message });
        navigate('/typeProduct/list');
      })
      .catch((error) => {
        setLoading(false);
        notificationController.error({ message: error.message });
      });
  };

  return (
    <BaseButtonsForm
      {...formItemLayout}
      isFieldsChanged={isFieldsChanged}
      onFieldsChange={() => setFieldsChanged(true)}
      name="validateForm"
      footer={
        <BaseButtonsForm.Item>
          <BaseButton type="primary" htmlType="submit" loading={isLoading}>
            {t('common.save')}
          </BaseButton>
        </BaseButtonsForm.Item>
      }
      onFinish={onFinish}
    >
      {/* Tên sản phẩm */}
      <BaseButtonsForm.Item
        name="name"
        label={t('forms.addTypeProduct.nameTypeProduct')}
        hasFeedback
        rules={[{ required: true, message: t('forms.addTypeProduct.nameTypeProductError') }]}
      >
        <S.InputsWrapper>
          <BaseInput defaultValue={name} />
        </S.InputsWrapper>
      </BaseButtonsForm.Item>
    </BaseButtonsForm>
  );
};
