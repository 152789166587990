import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { Product, getProduct } from '@app/api/product.api';
import { BaseTypography } from '../common/BaseTypography/BaseTypography';
import { Image, notification } from 'antd';
import { getOrder } from '@app/api/order.api';
import { useMounted } from '@app/hooks/useMounted';
import { set } from 'lodash';

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};
export interface ProductDetailProps {
  id: string;
}

export const ProductDetail: React.FC<ProductDetailProps> = ({ id }) => {
  const [product, setProduct] = useState<Product>({
    name: '',
    description: '',
    originPrice: 0,
    salePrice: 0,
    guide: '',
    effect: '',
    typeProduct: { id: '', name: '', numberOfProduct: 0 },
    remain: 0,
    deleteAt: new Date(),
    objectOfUse: '',
    images: [],
  });
  const { isMounted } = useMounted();
  const { t } = useTranslation();
  const fetch = useCallback(
    async (id: string) => {
      await getProduct(id)
        .then((response: any) => {
          if (isMounted.current) {
            setProduct(response);
          }
        })
        .catch((err) => {
          if (isMounted.current) {
            notification.error({ message: 'Lỗi khi lấy dữ liệu' });
          }
        });
    },
    [isMounted],
  );

  useEffect(() => {
    fetch(id);
  }, [fetch]);

  return (
    <BaseButtonsForm {...formItemLayout} isFieldsChanged={false} name="validateForm">
      {/* Tên sản phẩm */}
      <BaseButtonsForm.Item
        name="name"
        label={t('forms.addProduct.name_product')}
        hasFeedback
        initialValue={product.name}
        rules={[{ message: t('forms.addProduct.nameProductError') }]}
      >
        <BaseTypography.Text>{product.name}</BaseTypography.Text>
      </BaseButtonsForm.Item>
      {/* Loại sản phẩm */}
      <BaseButtonsForm.Item
        name="typeProduct"
        label={t('forms.addProduct.type_product')}
        hasFeedback
        initialValue={product.typeProduct.name}
        rules={[{ message: t('forms.addProduct.typeProductError') }]}
      >
        <BaseTypography.Text>{product.typeProduct.name}</BaseTypography.Text>
      </BaseButtonsForm.Item>
      {/* Số lượng sản phẩm */}
      <BaseButtonsForm.Item label={t('forms.addProduct.number')}>
        <BaseTypography.Text>{product.remain}</BaseTypography.Text>
      </BaseButtonsForm.Item>
      {/* Giá bán */}
      <BaseButtonsForm.Item
        name="salePrice"
        label={t('forms.addProduct.priceSale')}
        hasFeedback
        rules={[{ message: t('forms.addProduct.salePriceError') }]}
        initialValue={product.salePrice}
      >
        <BaseTypography.Text>{product.salePrice} đồng</BaseTypography.Text>
      </BaseButtonsForm.Item>
      {/* Giá gốc */}
      <BaseButtonsForm.Item
        name="originPrice"
        label={t('forms.addProduct.priceOrigin')}
        hasFeedback
        rules={[{ message: t('forms.addProduct.originPriceError') }]}
        initialValue={product.originPrice}
      >
        <BaseTypography.Text>{product.originPrice} đồng</BaseTypography.Text>
      </BaseButtonsForm.Item>
      {/* Mô tả */}
      <BaseButtonsForm.Item name="description" label={t('forms.addProduct.description')}>
        <BaseTypography.Text>{product.description}</BaseTypography.Text>
      </BaseButtonsForm.Item>
      {/* Tác dụng */}
      <BaseButtonsForm.Item name="effect" label={t('forms.addProduct.effect')}>
        <BaseTypography.Text>{product.effect}</BaseTypography.Text>
      </BaseButtonsForm.Item>
      {/* Hướng dẫn sử dụng */}
      <BaseButtonsForm.Item name="guide" label={t('forms.addProduct.guide')}>
        <BaseTypography.Text>{product.guide}</BaseTypography.Text>
      </BaseButtonsForm.Item>
      {/* Đối tượng sử dụng */}
      <BaseButtonsForm.Item name="objectOfUse" label={t('forms.addProduct.objectOfUse')}>
        <BaseTypography.Text>{product.objectOfUse}</BaseTypography.Text>
      </BaseButtonsForm.Item>
      {/* Up ảnh */}
      <BaseButtonsForm.Item name="upload" label="Ảnh sản phẩm">
        <Image.PreviewGroup>
          {product.images?.map((image, index) => (
            <Image key={index} width={100} src={image} />
          ))}
        </Image.PreviewGroup>
      </BaseButtonsForm.Item>
    </BaseButtonsForm>
  );
};
