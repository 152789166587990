// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// @ts-nocheck
import React, { ReactNode, useState, useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RangeValue } from 'rc-picker/lib/interface.d';
import { BaseHashTag, IHashTag } from '@app/components/common/BaseHashTag/BaseHashTag';
import { AuthorValidator, TitleValidator, DatesValidator, TagsValidator } from '../Validator';
import { useResponsive } from '@app/hooks/useResponsive';
import { newsTags as defaultTags } from '@app/constants/newsTags';
import { AppDate, Dates } from '@app/constants/Dates';
import { Post } from '@app/api/news.api';
import * as S from './NewsFilter.styles';
import { BaseDropdown } from '@app/components/common/BaseDropdown/Dropdown';
import { Pagination } from '@app/api/product.api';

interface NewsFilterProps {
  news: Post[];
  fetchNews: (pagination: Pagination, search?: string, startDate?: Date, endDate?: Date) => void;
  children: ({ filteredNews }: { filteredNews: Post[] }) => ReactNode;
}

interface Filter {
  onApply: (title: string, startDate?: AppDate, endDate?: AppDate) => void;
  onReset: () => void;
}

const Filter: React.FC<Filter> = ({ onApply, onReset }) => {
  const [title, setTitle] = useState<string>('');
  const [dates, setDates] = useState<[AppDate | null, AppDate | null]>([null, null]);
  const { t } = useTranslation();
  const { mobileOnly } = useResponsive();

  const applyFilter = () => {
    onApply(title, dates[0], dates[1]);
  };

  const resetFilter = () => {
    setTitle('');
    setDates([null, null]);
    onReset();
  };

  return (
    <S.FilterWrapper>
      {!mobileOnly && <S.FilterTitle>Lọc bài đăng</S.FilterTitle>}

      <S.InputWrapper>
        <S.SearchIcon />
        <S.Input placeholder="Tìm kiếm bằng tiêu đề" value={title} onChange={(event) => setTitle(event.target.value)} />
      </S.InputWrapper>

      <S.DateLabels>
        <S.DateLabel>Ngày bắt đầu</S.DateLabel>
        <S.DateLabel>Ngày kết thúc</S.DateLabel>
      </S.DateLabels>

      <S.RangePicker
        popupClassName="range-picker"
        value={dates}
        onChange={(dates: RangeValue<AppDate>) =>
          setDates([dates?.length ? dates[0] : null, dates?.length ? dates[1] : null])
        }
      />

      <S.BtnWrapper>
        <S.Btn onClick={() => resetFilter()}>Reset</S.Btn>
        <S.Btn onClick={() => applyFilter()} type="primary">
          Lọc
        </S.Btn>
      </S.BtnWrapper>
    </S.FilterWrapper>
  );
};

export const NewsFilter: React.FC<NewsFilterProps> = ({ news, fetchNews, children }) => {
  const [filterFields, setFilterFields] = useState<{
    title: string;
    dates: [AppDate | null, AppDate | null];
  }>({
    title: '',
    dates: [null, null],
  });
  const { title, dates } = filterFields;
  const [filteredNews, setFilteredNews] = useState<Post[]>(news);
  const [overlayOpen, setOverlayOpen] = useState<boolean>(false);
  const { mobileOnly } = useResponsive();
  const { t } = useTranslation();

  useEffect(() => {
    setFilteredNews(news);
    // TODO AT-183
    // eslint-disable-next-line
  });

  const handleClickApply = useCallback(
    (title?: string, startDate?: AppDate, endDate?: AppDate) => {
      const newPagination: Pagination = {
        page: 1,
        limit: 10,
      };
      fetchNews(newPagination, title, startDate?.toDate(), endDate?.toDate());
      if (mobileOnly) {
        setOverlayOpen(false);
      }
    },
    [mobileOnly],
  );

  const handleClickReset = useCallback(() => {
    fetchNews({ page: 1, limit: 10 });

    if (mobileOnly) {
      setOverlayOpen(false);
    }
  }, [mobileOnly]);

  return (
    <>
      <S.TitleWrapper>
        {mobileOnly && (
          <S.FilterPopover
            trigger="click"
            open={overlayOpen}
            onOpenChange={(open) => setOverlayOpen(open)}
            content={<Filter onApply={handleClickApply} onReset={handleClickReset} />}
          >
            <S.FilterButton>Lọc Tin tức</S.FilterButton>
          </S.FilterPopover>
        )}
      </S.TitleWrapper>

      <S.ContentWrapper>
        <S.NewsWrapper>{children({ filteredNews: filteredNews || news })}</S.NewsWrapper>

        {!mobileOnly && <Filter onApply={handleClickApply} onReset={handleClickReset} />}
      </S.ContentWrapper>
    </>
  );
};
