import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { ProductDetail } from '@app/components/productDetail/ProductDetail';
import { useLocation } from 'react-router-dom';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';

const ProductDetailPage: React.FC = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const { id } = state;
  return (
    <>
      <PageTitle>Chi tiết sản phẩm</PageTitle>
      <BaseRow gutter={[30, 30]}>
        <BaseCol xs={24} sm={24} xl={24}>
          <BaseButton type="primary" onClick={() => window.history.back()}>
            {t('Quay lại')}
          </BaseButton>

          <BaseCard id="validation form" title="Chi tiết sản phẩm" padding="1.25rem">
            <ProductDetail id={id} />
          </BaseCard>
        </BaseCol>
      </BaseRow>
    </>
  );
};

export default ProductDetailPage;
