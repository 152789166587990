import React from 'react';
import { Dates } from '@app/constants/Dates';
import { BaseHashTag, IHashTag } from '../BaseHashTag/BaseHashTag';
import { BaseImage } from '../BaseImage/BaseImage';
import { BaseAvatar } from '../BaseAvatar/BaseAvatar';
import * as S from './BaseArticle.styles';
import { Image } from 'antd';
import { useNavigate } from 'react-router-dom';
import { News } from '@app/api/news.api';

export interface BaseArticleProps {
  news: News;
  className?: string;
}

export const BaseArticle: React.FC<BaseArticleProps> = ({ news, className }) => {
  const navigate = useNavigate();
  const onClick = () => {
    navigate(`/news/detail/${encodeURIComponent(news.id || '')}`, { state: { news } });
  };
  return (
    <S.Wrapper className={className} onClick={onClick}>
      <Image src={news.thumbnailImage} alt="article" preview={false} />
      <S.InfoWrapper>
        <S.InfoHeader>
          <S.Title>{news.title}</S.Title>
          <S.DateTime>{Dates.format(news.createdAt || '', 'L')}</S.DateTime>
        </S.InfoHeader>
        <S.Description dangerouslySetInnerHTML={{ __html: news.subTitle }} />
      </S.InfoWrapper>
    </S.Wrapper>
  );
};
