// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// @ts-nocheck
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import DOMPurify from 'dompurify';
import * as S from './PolicyDetail.styles';
import { Button, Image, notification } from 'antd';
import { Dates } from '@app/constants/Dates';
import { ExclamationCircleFilled, LeftOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { useMounted } from '@app/hooks/useMounted';
import { Policy, getPolicy } from '@app/api/policy.api';
const { confirm } = Modal;

const PolicyDetail: React.FC = ({}) => {
  const [policy, setPolicy] = useState<Policy>({});
  const [loaded, setLoaded] = useState<boolean>(false);
  const { isMounted } = useMounted();

  const fetch = useCallback(async () => {
    await getPolicy()
      .then((res) => {
        if (isMounted.current) {
          setPolicy(res);
        }
      })
      .finally(() => setLoaded(true));
  }, [isMounted]);

  useEffect(() => {
    fetch();
  }, [fetch]);
  const navigate = useNavigate();
  const onClick = () => {
    navigate(`/policy/edit`, { state: { policy } });
  };

  return (
    <S.Wrapper>
      <S.InfoWrapper>
        <S.InfoHeader>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <S.CustomButton style={{ marginRight: '0.5rem' }} onClick={() => navigate(-1)}>
              <LeftOutlined />
            </S.CustomButton>

            <Button
              onClick={onClick}
              style={{ backgroundColor: 'var(--primary-color)', color: 'white', marginRight: '0.5rem' }}
            >
              Edit
            </Button>
          </div>
        </S.InfoHeader>
        <S.Description dangerouslySetInnerHTML={{ __html: policy.content }} />
      </S.InfoWrapper>
    </S.Wrapper>
  );
};

export default PolicyDetail;
