import { FC } from 'react';
import { ReactComponent as OrderIcon } from '@app/assets/icons/order.svg';
import { ReactComponent as NewIcon } from '@app/assets/icons/new.svg';
import { ReactComponent as ProductIcon } from '@app/assets/icons/product.svg';

export type StatisticColor = 'primary' | 'error' | 'secondary' | 'success';

interface ConfigStatistic {
  id: number;
  name: string;
  title: string;
  color: StatisticColor;
  Icon: FC;
}

export const statistics: ConfigStatistic[] = [
  {
    id: 1,
    name: 'Sản phẩm',
    title: 'Số lượng sản phẩm',
    color: 'success',
    Icon: ProductIcon,
  },
  {
    id: 2,
    name: 'Đơn hàng',
    title: 'Số lượng đơn hàng',
    color: 'error',
    Icon: OrderIcon,
  },
  {
    id: 3,
    name: 'Tin tức',
    title: 'Số lượng tin tức',
    color: 'primary',
    Icon: NewIcon,
  },
];
