import React from 'react';
import { useResponsive } from '@app/hooks/useResponsive';
import * as S from './NftCard.styles';
import { Collection } from '@app/api/collection.api';

interface NftCardProps {
  collection: Collection;
}

export const NftCard: React.FC<NftCardProps> = ({ collection }) => {
  const { isTablet } = useResponsive();

  const tabletLayout = (
    <>
      <S.InfoFooter>
        <S.CurrentBidWrapper>
          <S.CurrentBid>Số lượng sản phẩm</S.CurrentBid>
          <S.BidCrypto>{collection.numberOfProduct}</S.BidCrypto>
        </S.CurrentBidWrapper>
      </S.InfoFooter>
    </>
  );

  return (
    <S.Card padding={0} $img={'https://cdnmedia.baotintuc.vn/2017/11/04/12/36/sam-2.jpg'}>
      <S.NftImage src={'https://cdnmedia.baotintuc.vn/2017/11/04/12/36/sam-2.jpg'} alt="nftImage" />
      <S.NftInfo>
        <S.InfoRow>
          <S.Title>{collection.name}</S.Title>
        </S.InfoRow>
        {tabletLayout}
      </S.NftInfo>
    </S.Card>
  );
};
