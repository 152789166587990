// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// @ts-nocheck
import { News, deleteNews } from '@app/api/news.api';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import DOMPurify from 'dompurify';
import * as S from './NewsDetail.styles';
import { Button, Image, notification } from 'antd';
import { Dates } from '@app/constants/Dates';
import { ExclamationCircleFilled, LeftOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
const { confirm } = Modal;

const NewsDetail: React.FC = ({}) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { news } = state;
  const onClick = () => {
    navigate(`/news/edit/${encodeURIComponent(news.id || '')}`, { state: { news } });
  };

  const handleDeleteRow = async () => {
    await deleteNews(news.id)
      .then((data: any) => {
        notification.success({
          message: 'Xóa thành công',
        });
        navigate(-1);
      })
      .catch((error: any) => {
        notification.error({
          message: error.message,
        });
      });
  };

  const warning = async () => {
    confirm({
      title: 'Xác nhận xóa tin tức ',
      icon: <ExclamationCircleFilled />,
      content: 'Bạn có chắc chắn muốn xóa tin tức này không?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      async onOk() {
        await handleDeleteRow();
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };
  return (
    <S.Wrapper>
      <S.InfoWrapper>
        <S.InfoHeader>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <S.CustomButton style={{ marginRight: '0.5rem' }} onClick={() => navigate(-1)}>
              <LeftOutlined />
            </S.CustomButton>
            <S.Title>{news.title}</S.Title>
            <Button onClick={warning} danger style={{ marginRight: '0.5rem' }}>
              Delete
            </Button>
            <Button
              onClick={onClick}
              style={{ backgroundColor: 'var(--primary-color)', color: 'white', marginRight: '0.5rem' }}
            >
              Edit
            </Button>
          </div>
          <S.DateTime>{Dates.format(news.createdAt || '', 'L')}</S.DateTime>
        </S.InfoHeader>
        <S.Description dangerouslySetInnerHTML={{ __html: news.content }} />
      </S.InfoWrapper>
    </S.Wrapper>
  );
};

export default NewsDetail;
