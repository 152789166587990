import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { BaseSpin } from '../BaseSpin/BaseSpin';
import * as S from './BaseFeed.styles';

export interface BaseFeedProps {
  children: React.ReactNode[];
  target?: string;
}

export const BaseFeed: React.FC<BaseFeedProps> = ({ children }) => {
  return <S.NewsWrapper>{children}</S.NewsWrapper>;
};
