import React, { useEffect, useState, useCallback } from 'react';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { ColumnsType } from 'antd/es/table';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useTranslation } from 'react-i18next';
import { useMounted } from '@app/hooks/useMounted';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { useNavigate } from 'react-router-dom';
import { DeleteOutlined, EditOutlined, ExclamationCircleFilled, EyeOutlined } from '@ant-design/icons';
import { Product, deleteProduct, getProductList } from '@app/api/product.api';
import { Pagination } from '@app/api/product.api';
import { TypeProduct, getTypeProductList } from '@app/api/type-product.api';
import { Key } from 'antd/lib/table/interface';
import { Input, Modal, Tooltip, notification } from 'antd';
import { ProductDetail } from '@app/components/productDetail/ProductDetail';

const { confirm } = Modal;

const initialPagination: Pagination = {
  page: 1,
  limit: 10,
};

export const TableProduct: React.FC = () => {
  const [typeProductList, setTypeProductList] = useState([]);
  const [tableData, setTableData] = useState<{ data: Product[]; pagination: Pagination; loading: boolean }>({
    data: [],
    pagination: initialPagination,
    loading: false,
  });
  const [value, setValue] = useState('');
  const [typeProduct, setTypeProduct] = useState('');

  const { t } = useTranslation();
  const { isMounted } = useMounted();
  const navigate = useNavigate();

  const fetch = useCallback(
    async (pagination: Pagination, val?: string) => {
      setTableData((tableData) => ({ ...tableData, loading: true }));
      await getProductList(pagination, val)
        .then((response) => {
          if (isMounted.current) {
            setTableData({
              data: response.docs,
              pagination: {
                page: response.page,
                limit: response.limit,
                totalDocs: response.totalDocs,
              },
              loading: false,
            });
          }
        })
        .catch(() => {
          if (isMounted.current) {
            setTableData((tableData) => ({ ...tableData, loading: false }));
          }
        });
    },
    [isMounted],
  );

  useEffect(() => {
    getTypeProductList().then((res: any) => {
      if (isMounted.current) {
        setTypeProductList(res.data);
      }
    });
    fetch(initialPagination);
  }, [fetch]);

  const handleTableChange = (pagination: any) => {
    const newPagination: Pagination = {
      page: pagination.current,
      limit: pagination.pageSize,
    };
    fetch(newPagination, value);
  };

  const handleDeleteRow = async (productId: string) => {
    await deleteProduct(productId)
      .then((data: any) => {
        notification.success({
          message: 'Xóa thành công',
        });
        fetch(tableData.pagination);
      })
      .catch((error: any) => {
        notification.error({
          message: error.message,
        });
      });
    fetch(tableData.pagination);
  };

  const FilterByNameInput = (
    <Input
      placeholder="Search Name"
      value={value}
      onChange={(e) => {
        const currValue = e.target.value;
        setValue(currValue);
        const newPagination: Pagination = {
          page: 1,
          limit: 10,
        };
        fetch(newPagination, currValue);
      }}
    />
  );

  const warning = async (productId: string) => {
    confirm({
      title: 'Xác nhận xóa sản phẩm này',
      icon: <ExclamationCircleFilled />,
      content: 'Bạn có chắc chắn muốn xóa sản phẩm này không?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      async onOk() {
        await handleDeleteRow(productId);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const onProductDetail = (product: Product) => {
    Modal.info({
      title: 'Chi tiết sản phẩm',
      content: <ProductDetail id={product.id || ''} />,
      width: 1000,
      maskClosable: true,
      centered: true,
    });
    // navigate(`/product/detail/${product.id}` , {
    //   state: {
    //     id: product.id,
    //   },
    // });
  };

  const columns: ColumnsType<Product> = [
    {
      title: FilterByNameInput,
      dataIndex: 'name',
      onFilter: (value, record) => record.name.includes(value as string),
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: t('productList.typeProduct'),
      dataIndex: 'typeProduct',
      render: (typeProduct: TypeProduct) => <span>{typeProduct.name}</span>,
      filterMode: 'tree',
      filterSearch: true,
      filters: typeProductList.map((typeProduct: TypeProduct) => ({ text: typeProduct.name, value: typeProduct.name })),
      onFilter: (value: boolean | Key, record: Product) => record.typeProduct.name.includes(value.toString()),
    },
    {
      title: t('productList.originPrice'),
      dataIndex: 'originPrice',
      sorter: (a, b) => a.originPrice - b.originPrice,
    },
    {
      title: t('productList.salePrice'),
      dataIndex: 'salePrice',
      sorter: (a, b) => a.salePrice - b.salePrice,
    },
    {
      title: 'Số lượng hàng còn lại',
      dataIndex: 'remain',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.remain - b.remain,
    },
    {
      title: t('productList.actions'),
      dataIndex: 'actions',
      width: '15%',
      render: (text: string, record: Product) => {
        return (
          <BaseSpace>
            <Tooltip title={t('productList.detail')}>
              <BaseButton type="link" onClick={() => onProductDetail(record)}>
                <EyeOutlined />
              </BaseButton>
            </Tooltip>

            <Tooltip title={t('productList.edit')}>
              <BaseButton
                type="link"
                onClick={() => {
                  navigate(`/product/edit/${encodeURIComponent(record.id || '')}`, {
                    state: {
                      product: record,
                    },
                  });
                }}
              >
                <EditOutlined />
              </BaseButton>
            </Tooltip>
            <Tooltip title={t('productList.delete')}>
              <BaseButton type="default" danger onClick={() => warning(record.id || '')}>
                <DeleteOutlined />
              </BaseButton>
            </Tooltip>
          </BaseSpace>
        );
      },
    },
  ];

  return (
    <BaseTable
      columns={columns}
      dataSource={tableData.data}
      pagination={{
        current: tableData.pagination.page,
        pageSize: tableData.pagination.limit,
        total: tableData.pagination.totalDocs,
      }}
      loading={tableData.loading}
      onChange={handleTableChange}
      scroll={{ x: 800 }}
      bordered
    />
  );
};
