import React, { useEffect, useState, useCallback } from 'react';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { useMounted } from '@app/hooks/useMounted';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { useNavigate } from 'react-router-dom';
import { Typography } from 'antd';
const { Title } = Typography;

import SubmitShipped from '@app/components/modals/OrderModals/submitShippedModal';
import { Order, OrderProduct, Pagination, getOrder } from '@app/api/order.api';
import { notification } from 'antd';

const initialPagination: Pagination = {
  page: 1,
  limit: 10,
};

export const TableOrderDetail: React.FC<{ id: string }> = ({ id }) => {
  const [tableData, setTableData] = useState<{ data: Order; pagination: Pagination; loading: boolean }>({
    data: {
      id: '',
      customerName: '',
      customerPhone: '',
      customerEmail: '',
      customerAddress: '',
      shipped: false,
      createdAt: '',
      total: 0,
      orderProducts: [],
    },
    pagination: initialPagination,
    loading: false,
  });
  const { isMounted } = useMounted();
  const { t } = useTranslation();

  const fetch = useCallback(
    async (id: string) => {
      setTableData((tableData) => ({ ...tableData, loading: true }));
      await getOrder(id)
        .then((response: any) => {
          if (isMounted.current) {
            setTableData({
              data: response,
              pagination: {
                page: 1,
                limit: 10,
                totalDocs: response.orderProducts.length,
              },
              loading: false,
            });
          }
        })
        .catch((err) => {
          if (isMounted.current) {
            notification.error({ message: 'Lỗi khi lấy dữ liệu' });
            setTableData((tableData) => ({ ...tableData, loading: false }));
          }
        });
    },
    [isMounted],
  );

  useEffect(() => {
    fetch(id);
  }, [fetch]);

  const navigate = useNavigate();

  const columns: ColumnsType<OrderProduct> = [
    {
      title: t('tableOrderDetail.productName'),
      dataIndex: 'name',
      render: (text: string, record: { id: string }) => (
        <a
          style={{ color: 'red' }}
          onClick={() =>
            navigate(`/product/detail/${id}`, {
              state: { id: record.id },
            })
          }
        >
          {text}
        </a>
      ),
    },
    {
      title: t('tableOrderDetail.count'),
      dataIndex: 'quantity',
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: t('tableOrderDetail.cost'),
      dataIndex: 'price',
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: 'Tổng tiền',
      dataIndex: 'total',
      width: '15%',
      render: (text: string) => {
        return (
          <BaseSpace>
            <span>{text}</span>
          </BaseSpace>
        );
      },
    },
  ];

  return (
    <div>
      <BaseTable
        columns={columns}
        dataSource={tableData.data.orderProducts}
        pagination={{
          current: tableData.pagination.page,
          pageSize: tableData.pagination.limit,
          total: tableData.pagination.totalDocs,
        }}
        loading={tableData.loading}
        scroll={{ x: 800 }}
        bordered
      />
      <Title level={4}>{'Tổng giá trị đơn hàng:  ' + tableData.data?.total}</Title>
    </div>
  );
};
