import React from 'react';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { getDifference } from 'utils/utils';
import * as S from './StatisticsInfo.styles';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';

interface StatisticsInfoProps {
  name: string;
}

export const StatisticsInfo: React.FC<StatisticsInfoProps> = ({ name }) => {
  return (
    <BaseSpace direction="vertical" size={6}>
      <S.Title>{name}</S.Title>
    </BaseSpace>
  );
};
