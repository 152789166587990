import { httpApi } from './http.api';

export interface Pagination {
  page?: number;
  limit?: number;
  totalDocs?: number;
}

export interface OrderList {
  id: string;
  customerName: string;
  customerPhone: string;
  customerEmail: string;
  customerAddress: string;
  shipped: boolean;
  createdAt: string;
  total: number;
  // orderProducts: OrderProduct[];
}

export interface Order {
  id: string;
  customerName: string;
  customerPhone: string;
  customerEmail: string;
  customerAddress: string;
  shipped: boolean;
  createdAt: string;
  total: number;
  orderProducts: OrderProduct[];
}

export interface OrderProduct {
  id: string;
  name: string;
  quantity: number;
  price: number;
  total: number;
}

export interface OrderListResponse {
  docs: OrderList[];
  totalDocs: number;
  limit: number;
  page: number;
  totalPages: number;
}

export const getOrderList = (pagination: Pagination): Promise<OrderListResponse> => {
  return httpApi.get<OrderListResponse>('admin/order', { params: { ...pagination } }).then(({ data }) => data);
};

export const getOrder = (id: string): Promise<Order> => {
  return httpApi.get<Order>(`admin/order/${id}`).then(({ data }) => data);
};

export const deleteOrder = (id: string) => {
  return httpApi.delete<void>(`admin/order/${id}`).then(({ data }) => data);
};

export const updateOrder = (id: string, shipped: boolean) => {
  return httpApi.patch<void>(`admin/order/${id}`, { shipped }).then(({ data }) => data);
};
