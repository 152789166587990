import styled from 'styled-components';
import { BORDER_RADIUS, FONT_SIZE, FONT_WEIGHT, media } from '@app/styles/themes/constants';
import { BaseTypography } from '../BaseTypography/BaseTypography';

export const Header = styled.div`
  height: 5.5rem;
  margin-left: 1.5625rem;
  display: flex;
  align-items: center;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 5.25rem;
  position: relative;
  max-width: 100%;
  height: auto;
  box-shadow: var(--box-shadow);
  border-radius: ${BORDER_RADIUS};
  transition: 0.3s;

  [data-theme='dark'] & {
    background: var(--secondary-background-color);
  }

  &:hover {
    box-shadow: var(--box-shadow-hover);
  }

  img {
    display: block;
    transition: all 0.5s ease;
    width: 100%;
    height: auto;
  }

  @media only screen and ${media.md} {
    flex-direction: row;
    max-width: 70.5rem;
    height: 14.5rem;

    img {
      width: 300px;
      height: 188px;
    }
  }
`;

export const InfoWrapper = styled.div`
  padding: 0.5rem;

  @media only screen and ${media.md} {
    padding: 1rem;
  }
`;

export const InfoHeader = styled.div`
  display: flex;
  margin-bottom: 1rem;
  max-width: 100%;
  flex-direction: column;

  @media only screen and ${media.md} {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0.625rem;
  }

  @media only screen and ${media.xxl} {
    margin-bottom: 1.25rem;
  }
`;

export const Title = styled.div`
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
  width: 100%;
  line-height: 1.375rem;
  color: var(--text-main-color);

  @media only screen and ${media.md} {
    font-size: ${FONT_SIZE.xxl};
  }
`;

export const DateTime = styled(BaseTypography.Text)`
  font-size: ${FONT_SIZE.xs};
  color: var(--text-main-color);
  line-height: 1.25rem;
`;

export const Description = styled.div`
  font-size: ${FONT_SIZE.xs};
  color: var(--text-main-color);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  @media only screen and ${media.xxl} {
    font-size: 1rem;
  }
`;
