import React from 'react';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { TableNews } from '../tableNews/TableNews';
import { NewsFeed } from '@app/components/news/newsFeed/NewsFeed';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useTranslation } from 'react-i18next';

const NewsListPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageTitle>Tin Tức</PageTitle>
      <NewsFeed />
    </>
  );
};

export default NewsListPage;
