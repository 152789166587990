import { BaseTypography } from '@app/components/common/BaseTypography/BaseTypography';
import { FONT_SIZE, FONT_WEIGHT } from '@app/styles/themes/constants';
import { BaseProgress } from '@app/components/common/BaseProgress/BaseProgress';
import styled from 'styled-components';

export const ValueText = styled(BaseTypography.Text)`
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
`;

export const StyledBaseProgress = styled(BaseProgress)<{ strokeColor?: string }>`
  &:hover {
    background-color: ${(props) => props.strokeColor};
    ${ValueText} {
      color: white;
    }
    border-radius: 50%;
  }
`;
