import { httpApi } from '@app/api/http.api';

export interface Collection {
  name: string;
  numberOfProduct: string;
}

export const getCollection = (): Promise<Collection[]> => {
  return httpApi.get<Collection[]>(`collections`).then(({ data }) => data);
};
