import React, { useCallback, useEffect, useState } from 'react';
import { Button, Modal, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { TableOrderDetail } from '@app/components/tables/tableOrder/tableOrderDetail';
import { Typography } from 'antd';
import { Order, getOrder } from '@app/api/order.api';
import { useMounted } from '@app/hooks/useMounted';

const { Title } = Typography;
const OrderDetailModal: React.FC<{ id: string }> = ({ id }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [order, setOrder] = useState<Order>();
  const { isMounted } = useMounted();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Chi tiết đơn hàng
      </Button>
      <Modal title="Basic Modal" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} width={1000}>
        <TableOrderDetail id={id} />
      </Modal>
    </>
  );
};

export default OrderDetailModal;
