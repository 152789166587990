import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// no lazy loading for auth pages to avoid flickering
const AuthLayout = React.lazy(() => import('@app/components/layouts/AuthLayout/AuthLayout'));
import LoginPage from '@app/pages/LoginPage';
import ForgotPasswordPage from '@app/pages/ForgotPasswordPage';
import NewPasswordPage from '@app/pages/NewPasswordPage';
import LockPage from '@app/pages/LockPage';
import Logout from './Logout';

import MainLayout from '@app/components/layouts/main/MainLayout/MainLayout';
import RequireAuth from '@app/components/router/RequireAuth';
import { withLoading } from '@app/hocs/withLoading.hoc';
import NftDashboardPage from '@app/pages/DashboardPages/NftDashboardPage';
import ProductListPage from '@app/pages/Products/ProductListPage/ProductListPage';
import ProductAddPage from '@app/pages/Products/ProductAddPage/ProductAddPage';
import ProductEditPage from '@app/pages/Products/ProductEditPage/ProductEditPage';
import ProductDetailPage from '@app/pages/Products/ProductDetailPage/ProductDetailPage';
import TypeProductListPage from '@app/pages/TypeProduct/TypeProductListPage/TypeProductListPage';
import TypeProductAddPage from '@app/pages/TypeProduct/TypeProductAddPage/TypeProductAddPage';
import TypeProductEditPage from '@app/pages/TypeProduct/TypeProductEditPage/TypeProductEditPage';
import NewsAddPage from '@app/pages/News/NewsAddPage/NewsAddPage';
import NewsListPage from '@app/pages/News/NewsListPage/NewsListPage';
import NewsDetailPage from '@app/pages/News/NewsDetailPage/NewsDetailPage';
import NewsEditPage from '@app/pages/News/NewsEditPage/NewsEditPage';
import OrderListPage from '@app/pages/Order/OrderListPage/OrderListPage';
import PolicyPage from '@app/pages/PolicyPage';
import PolicyEditPage from '@app/pages/PolicyEditPage';
import IntroductionPage from '@app/pages/IntroductionPage';
import IntroductionEditPage from '@app/pages/IntroductionEditPage';

export const HOME_PATH = '/';

const NftDashboard = withLoading(NftDashboardPage);
const ProductList = withLoading(ProductListPage);
const ProductAdd = withLoading(ProductAddPage);
const ProductEdit = withLoading(ProductEditPage);
const ProductDetail = withLoading(ProductDetailPage);
const TypeProductList = withLoading(TypeProductListPage);
const TypeProductAdd = withLoading(TypeProductAddPage);
const TypeProductEdit = withLoading(TypeProductEditPage);
const NewsAdd = withLoading(NewsAddPage);
const NewsList = withLoading(NewsListPage);
const NewsDetail = withLoading(NewsDetailPage);
const NewsEdit = withLoading(NewsEditPage);
const OrderList = withLoading(OrderListPage);
const Policy = withLoading(PolicyPage);
const PolicyEdit = withLoading(PolicyEditPage);
const Introduction = withLoading(IntroductionPage);
const IntroductionEdit = withLoading(IntroductionEditPage);

const AuthLayoutFallback = withLoading(AuthLayout);
const LogoutFallback = withLoading(Logout);

export const AppRouter: React.FC = () => {
  const protectedLayout = (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  );

  return (
    <BrowserRouter>
      <Routes>
        <Route path={HOME_PATH} element={protectedLayout}>
          {/* HomePage Router */}
          <Route index element={<NftDashboard />} />
          {/* Products Router */}
          <Route path="product">
            <Route path="list" element={<ProductList />} />
            <Route path="add" element={<ProductAdd />} />
            <Route path="edit/:id" element={<ProductEdit />} />
            <Route path="detail/:id" element={<ProductDetail />} />
          </Route>
          <Route path="typeProduct">
            {/* <Route path='list' element={<TypePro />} /> */}
            <Route path="list" element={<TypeProductList />} />
            <Route path="add" element={<TypeProductAdd />} />
            <Route path="edit/:id" element={<TypeProductEdit />} />
          </Route>

          {/* News Router */}
          <Route path="news">
            <Route path="add" element={<NewsAdd />} />
            <Route path="edit/:id" element={<NewsEdit />} />
            <Route path="list" element={<NewsList />} />
            <Route path="detail/:id" element={<NewsDetail />} />
          </Route>
          <Route path="order">
            <Route path="list" element={<OrderList />} />
          </Route>

          <Route path="policy">
            <Route index element={<Policy />} />
            <Route path="edit" element={<PolicyEdit />} />
          </Route>

          <Route path="introduction">
            <Route index element={<Introduction />} />
            <Route path="edit" element={<IntroductionEdit />} />
          </Route>
        </Route>
        <Route path="/auth" element={<AuthLayoutFallback />}>
          <Route path="login" element={<LoginPage />} />
          <Route
            path="lock"
            element={
              <RequireAuth>
                <LockPage />
              </RequireAuth>
            }
          />
          <Route path="forgot-password" element={<ForgotPasswordPage />} />
          <Route path="new-password" element={<NewPasswordPage />} />
        </Route>
        <Route path="/logout" element={<LogoutFallback />} />
      </Routes>
    </BrowserRouter>
  );
};
